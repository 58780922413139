import { createTheme } from '@mui/material/styles';

import { StockIndexEnum } from './enums';

const theme = createTheme({
  zIndex: {
    tooltip: 999
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 905,
      lg: 1240,
      xl: 1440,
    }
  },
  shape: {
    borderRadius: 5
  },

  palette: {
    primary: {
      main: "#0018F2",
      light: "rgba(0, 24, 242, 0.5)",
      dark: "#2A00A2",
    },
    success: {
      main: "#0DC589",
      light: "rgba(13, 197, 137, 0.1)",

    },
    error: {
      main: "#F54C56",
      light: "rgba(245, 76, 86, 0.2)",
    },
    warning: {
      main: '#FFB93B'
    },
    background: {
      default: "#F5F6FA",
      paper: "rgba(0, 0, 0, 0.04)"
    },
    text: {
      primary: "#252B42",
      secondary: "#6E7191",
      disabled: "#A0A3BD"
    }
  },

  typography: {
    fontSize: 12,
    htmlFontSize: 12,
    body1: {
      fontSize: 14,
    },
    h4: {
      fontSize: 26,
    },
    h5: {
      fontSize: '1.75rem',
    },
    h6: {
      letterSpacing: '0.2px',
      fontSize: 16,
      fontWeight: 700
    }
  },

  app: {
    drawer: {
      insight: {
        width: 400
      }
    },
    insight: {
      color: {
        risk: {
          important: "rgba(245, 76, 86, 0.35)",
          medium: "rgba(245, 76, 86, 0.15)",
          low: "rgba(245, 76, 86, 0.05)",
        },
        highlight: {
          important: "rgba(13, 197, 137, 0.35)",
          medium: "rgba(13, 197, 137, 0.15)",
          low: "rgba(13, 197, 137, 0.05)",
        },
      }
    },
    palette: {
      tertiary: "#D9DBE9"
    },
    chart: {
      font: {
        family: "sans-serif"
      },
      color: {
        grid: "#D9DBE9",
        volumeBar: "#9898D5",
      }
    },
    fixedTop: {
      mdDown: 64,
      mdUp: 80,
    },
    typography: {
      paragraph: {
        lineHeight: 1.5
      },
    },
    button: {
      borderRadius: 40,
      fontWeight: 600,
      maxWidth: 150,
    },
    border: {
      color: {
        default: 'rgba(236, 237, 246, 1)',
        secondary: '#FFB93B'
      }
    },
    borderRadius: {
      paper: 10,
      cardPrimary: 10,
      cardSecondary: 10,
      chipPrimary: 5,
      chipSecondary: 10,
    },
    height: {
      chipSecondary: 22,
      loading: {
        rect: 118
      }
    },
    learning: {
      popper: {
        background: "#191D31",
        box: {
          default: {
            background: "rgba(255, 255, 255, 0.15)",
            borderColor: "#3E60FF"
          },
          secondary: {
            background: "linear-gradient(321.69deg, rgba(255, 97, 136, 0.3) 0%, rgba(255, 177, 153, 0.3) 100%)",
            borderColor: "linear-gradient(321.69deg, rgba(255, 97, 136, 0.3) 0%, rgba(255, 177, 153, 0.3) 100%)" 
          }
        }
      }
    },
    background: {
      cardPrimary: "#FFFFFF",
      cardSecondary: "#F5F6FA",
      chipPrimary: "#D9DBE9",
      chipSecondary: "#0018F2",
      textInput: "#ECEDF7",
    },
    stockIndex: {
      color: {
        [StockIndexEnum.Dow]: "#F96E51",
        [StockIndexEnum.Nasdaq]: "#3FD4EA",
        [StockIndexEnum.Sp]: "#2E93FD",
        [StockIndexEnum.Russell]: "rgba(155, 81, 224, 0.7)",
      }
    }
  }
});

export { theme };