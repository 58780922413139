import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';

import {
  Box
} from 'src/components/mui';
import { Paths } from 'src/routes/paths';

function Footer() {

  const theme = useTheme();
  const matchesDownSm = useMediaQuery(theme.breakpoints.down('md'));

  const containerFlexDirection = matchesDownSm ? 'column-reverse' : 'row';

  const padding = matchesDownSm ? `2rem 0 ${theme.app.fixedTop.mdDown}px 0` : '2rem';

  return (
    <footer style={{ backgroundColor: 'white', width: '100%', padding, marginTop: '1rem' }}>
      <Container maxWidth="xl" sx={{ display: "flex", flexDirection: containerFlexDirection, justifyContent: "space-between" }}>
        <Box p={matchesDownSm ? '2rem 0 2rem 0' : 0} sx={{ maxWidth: matchesDownSm ? 'none' : 400 }}>
          <Typography variant="h5" color={theme.palette.primary.main} fontWeight={700} >Poodle Finance</Typography>
          <Typography variant="body2" color="textSecondary">
            {
              `
              © 2022 PoodleFinance, Inc. All rights reserved.
              Any advice contained in this website is general advice only and please use according to your own investment objectives
              `
            }
          </Typography>
        </Box>
        <Box display="flex" justifyContent={matchesDownSm ? "space-between" : "space-around"} flexGrow={1}>
          <Box>
            <Typography variant="body1" color="textPrimary" fontWeight={700}>Company</Typography>
            <Link 
              component={RouterLink} 
              to={Paths.ABOUT_US}
              underline="none"
            >
              <Typography variant="body2" color="textSecondary">
                About Us
              </Typography>
            </Link>
            {/* <Typography variant="body2" color="textSecondary">
              Terms and Conditions
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Privacy Policy
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Support
            </Typography> */}
          </Box>
          <Box>
            <Typography variant="body1" color="textPrimary" fontWeight={700}>Product Features</Typography>
            <Typography variant="body2" color="textSecondary">
              Stock Analysis
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Investment Learning
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1" color="textPrimary" fontWeight={700}>Markets</Typography>
            <Typography variant="body2" color="textSecondary">
              US Stock
            </Typography>
          </Box>
        </Box>
      </Container>
    </footer>
  )
}

export default Footer;