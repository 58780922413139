export const Paths = {
  LANDING: '/',
  HOME: '/app',
  SEARCH: '/search',
  STOCK: '/stock',
  WATCHLIST: '/watchlist',
  SCREENER: '/screener',
  NEWS: '/news',
  SETTINGS: '/settings',
  SIGN_UP: '/signUp',
  ABOUT_US: '/about-us',
}

export enum StockPathEntity {
  Overview = "overview",
  Research = "research",
  Valuations = "valuations",
  Financials = "financials",
  Comparisons = "comparisons",
  Ownerships = "ownerships",
  News = "news",
}

export const stockPathPattern = 
  `/stock/:entity(${StockPathEntity.Overview}|${StockPathEntity.Research}|${StockPathEntity.Valuations}|${StockPathEntity.Financials}|${StockPathEntity.Comparisons}|${StockPathEntity.Ownerships}|${StockPathEntity.News})/:symbolId`;

export const newsPathPattern = `/news/:newsId`;