import { Theme } from '@mui/material/styles';

import { QuoteChange, GroupDataType } from 'src/enums';
import { displayDate } from './dateUtils';
import { displayNumber } from './numberUtils';
import { ChartOverviewUi } from 'src/types';

export function getChangeColor({
  isCompare = true,
  prevChange, 
  change,
  isBg = false,
  isBgMain = false,
  isMarketOpen,
  theme,
} : {
  isCompare?: boolean;
  prevChange?: string;
  change: string | undefined;
  isBg?: boolean;
  isBgMain?: boolean;
  isMarketOpen: boolean;
  theme: Theme;
}): string {

  if(isBg && !isMarketOpen) {
    return theme.palette.background.default;
  }

  const previousChangeNum = Number(prevChange);
  const changeNum = Number(change);

  if(!change || isNaN(changeNum)) {
    return isBg ? theme.palette.background.default : 'text.primary';
  }

  if(isCompare && (!prevChange || isNaN(previousChangeNum))) {
    return isBg ? theme.palette.background.default : 'text.primary';
  }

  const flatPredicate = isCompare ? changeNum === previousChangeNum : changeNum === 0;
  const downPredicate = isCompare ? changeNum < previousChangeNum : change.indexOf('-') !== -1;

  const bgSuccess = isBgMain ? theme.palette.success.main : theme.palette.success.light;
  const bgError = isBgMain ? theme.palette.error.main : theme.palette.error.light;

  if(flatPredicate) {
    return isBg ? theme.palette.background.default : 'text.primary';
  }

  if(downPredicate) {
    return isBg ? bgError : 'error.main';
  }

  return isBg ? bgSuccess : 'success.main';
}

export function renderChangeDirection(changeDirection: QuoteChange) {
  return changeDirection === QuoteChange.Up
    ? "+"
    : "";
}

export function displayGroupData({ value, dataType, showCurrency = false } : {
  value: string;
  dataType: GroupDataType | undefined;
  showCurrency: boolean;
}) {
  if(!dataType || dataType === GroupDataType.Absolute) {
    return `${showCurrency ? '$ ' : ''}${displayNumber({ input: value, showUnit: true })}`;
  }

  if(dataType === GroupDataType.Date) {
    return displayDate(value);
  }

  if(dataType === GroupDataType.Multiple) {
    return `${value}X`;
  }

  if(dataType === GroupDataType.Percentage) {
    return `${value}%`;
  }

  throw new Error(`Unhandled data type ${dataType}`);
}

export function getPriceChangePercentage(chartData: ChartOverviewUi[] | undefined): string | undefined {
  if(!chartData || !chartData.length) return;

  const startDayClosePrice = +chartData[0].close;
  const lastDayClosePrice = +chartData[chartData.length - 1].close;

  if(!startDayClosePrice || !lastDayClosePrice) return '-';

  return `${(lastDayClosePrice - startDayClosePrice) / startDayClosePrice}`;
}