import { SyntheticEvent } from 'react';
import Typography from '@mui/material/Typography';
import AddCircleRounded from '@mui/icons-material/AddCircleRounded';
import RemoveCircleRounded from '@mui/icons-material/RemoveCircleRounded';
import { useTheme } from '@mui/material/styles';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';

import { 
  BoxSecondary,
  Grid,
  Box,
  IconButton
} from "src/components/mui";
import { StockLineChart } from 'src/components/charts';
import { useQuotes, useChartOverview } from 'src/hooks';
import { displayNumber } from "src/utils/numberUtils";
import { displayString } from 'src/utils/stringUtils';
import { StockPathEntity } from 'src/routes/paths';
import { toStock } from 'src/routes/navigation';
import { Symbol } from 'src/types';
import { getChangeColor, getPriceChangePercentage } from 'src/utils';
import { displayPercentage } from 'src/utils/numberUtils';
import { useCognitoUser } from 'src/contexts';

function SymbolSummary({
  symbol,
  isRecommended,
  showFollowButton
} : {
  symbol: Symbol | undefined,
  isRecommended: boolean,
  showFollowButton?: {
    handleFollow: React.EventHandler<SyntheticEvent>,
    handleUnfollow: React.EventHandler<SyntheticEvent>,
  }
}) {
  const theme = useTheme();

  const symbols = symbol ? [symbol.symbol] : [];

  const { authenticatedUser } = useCognitoUser();

  const { data: realtimeQuotes } = useQuotes(symbols);
  const { data: sixMonthsChartData } = useChartOverview('6m', symbols);
  const { data: oneYearChartData } = useChartOverview('1y', symbols);
  const { data: ytdChartData } = useChartOverview('ytd', symbols);

  const realtimeQuote = realtimeQuotes?.find((quote) => quote.symbol === symbol?.symbol);
  const isMarketOpen = realtimeQuote?.isUSMarketOpen !== 'False';
  
  const sixMonthsChange = getPriceChangePercentage(sixMonthsChartData);
  const oneYearChange = getPriceChangePercentage(oneYearChartData);

  const sixMonthsChangeColor = getChangeColor({ isCompare: false, change: sixMonthsChange, isMarketOpen, theme });
  const oneYearChangeColor = getChangeColor({ isCompare: false, change: oneYearChange, isMarketOpen, theme });
  const ytdChangeColor = getChangeColor({ isCompare: false, change: realtimeQuote?.ytdChange, isMarketOpen, theme });

  return (
    <BoxSecondary mb={2}>
      <Grid container spacing={1}>

        <Grid item display="flex" alignItems="center" xs={7} sm={4}>
          {
            showFollowButton ? (
              authenticatedUser 
                ? isRecommended 
                  ? <IconButton variant="text" onClick={showFollowButton.handleFollow}>
                      <AddCircleRounded />
                    </IconButton>
                  : <IconButton variant="text" onClick={showFollowButton.handleUnfollow}>
                      <RemoveCircleRounded />
                    </IconButton>
                : <IconButton variant="text" onClick={showFollowButton.handleFollow}>
                    <AddCircleRounded />
                  </IconButton>
            ) : null
          }
          
          <Box display="flex" flexDirection="column">
            <Link 
              component={RouterLink} 
              to={toStock(StockPathEntity.Overview, symbol?.id)}
              underline="none"
            >
              <Typography variant="body1" textAlign="center" color={theme.palette.text.primary} sx={{ fontWeight: 600 }}>
                {symbol?.symbol}
              </Typography>
            </Link>
            <Link 
              component={RouterLink} 
              to={toStock(StockPathEntity.Overview, symbol?.id)}
              underline="none"
            >
              <Typography variant="body1" textAlign="center" color={theme.palette.text.disabled} noWrap sx={{ fontWeight: 600 }}>
                {symbol?.company?.companyName}
              </Typography>
            </Link>
          </Box>
        </Grid>

        <Grid item xs={5} sm={2} display="flex" alignItems="center">
          <Typography variant="h6" textAlign="center" color="textSecondary" noWrap sx={{ fontWeight: 600 }}>
            { `${displayNumber({ input: realtimeQuote?.latestPrice })} ${displayString(realtimeQuote?.currency)}` }
          </Typography>
        </Grid>
        
        <Grid item container spacing={1} xs={12} sm={6} display="flex" justifyContent="center" alignItems="center">
          <Grid item xs={2}>
            <Typography variant="body1" textAlign="center" color={theme.palette.text.disabled} sx={{ fontWeight: 500 }}>
              6M
            </Typography>
            <Typography variant="body2" textAlign="center" color={sixMonthsChangeColor} sx={{ fontWeight: 500 }}>
              { displayPercentage({ input: sixMonthsChange }) }
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body1" textAlign="center" color={theme.palette.text.disabled} sx={{ fontWeight: 500 }}>
              YTD
            </Typography>
            <Typography variant="body2" textAlign="center" color={ytdChangeColor} sx={{ fontWeight: 500 }}>
              { displayPercentage({ input: realtimeQuote?.ytdChange }) }
            </Typography> 
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body1" textAlign="center" color={theme.palette.primary.main} sx={{ fontWeight: 700 }}>
              1Y
            </Typography>
            <Typography variant="body2" textAlign="center" color={oneYearChangeColor} sx={{ fontWeight: 500 }}>
              { displayPercentage({ input: oneYearChange }) }
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <StockLineChart data={ytdChartData} range="1y" chartHeight={50} />
          </Grid>
        </Grid>
      </Grid>
    </BoxSecondary>
  );
}

export default SymbolSummary;