import { useEffect } from 'react'
import {
  Switch,
  Redirect,
  useLocation
} from "react-router-dom";
import * as Sentry from "@sentry/react";
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';

import {
	StyledPageContainer,
} from 'src/pages/styled';
import {
  Box
} from 'src/components/mui';
import { SentryRoute } from './components';
import { Header, BottomNavigation, Footer } from 'src/parts';
import Home from 'src/pages/home';
import Stock from 'src/pages/stock';
import SearchMobile from 'src/pages/search';
import Watchlist from 'src/pages/watchlist';
import Settings from 'src/pages/settings';
import AboutUs from './pages/aboutUs';
import Notification from 'src/parts/Notification';
import { InsightDrawer, InsightPopper } from "src/parts/Learning";
import Iframe from './components/iframe';
import { Paths, stockPathPattern } from 'src/routes/paths';
import { useCognitoUser, useLearning, useModal } from 'src/contexts';
import { LearningType, ModalType } from './enums';
import LearningPopperAnchor from './components/anchorEl/LearningPopper';

const StyledMain = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme }) => ({
  position: "relative",
  width: "100%",
  maxWidth: theme.breakpoints.values.xl
}));

function App() {
  const { authenticatedUser } = useCognitoUser();
  const { pathname } = useLocation();

  const theme = useTheme();
  const matchesDownSm = useMediaQuery(theme.breakpoints.down('md'));
  const { state } = useLearning();
  const { dispatch } = useModal();

  useEffect(() => {
    if(pathname === Paths.SIGN_UP && !authenticatedUser?.username) {
      dispatch({
        type: ModalType.SignUp
      });
    }
  }, [dispatch, pathname, authenticatedUser]);

  useEffect(() => {
    Sentry.setUser({ username: authenticatedUser?.username ?? 'anonymous guest'})
  }, [authenticatedUser]);

  const shouldRenderLanding = pathname === Paths.LANDING && !authenticatedUser?.username;

  const shouldRenderAboutUs = pathname === Paths.ABOUT_US;

  if(shouldRenderLanding) {
    return (
      <Iframe src="https://home.poodlefinance.com" width='100%' height='100vh' />
    );
  }

  if(shouldRenderAboutUs) {
    return (
      <AboutUs />
    );
  }

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Header open={state.type !== LearningType.None} />
      <StyledMain open={state.type !== LearningType.None}>
        <LearningPopperAnchor />
        <Notification />
        <Switch>
          <SentryRoute path={stockPathPattern}>
            <StyledPageContainer maxWidth={false}>
              <Stock />
            </StyledPageContainer>
          </SentryRoute>
          <SentryRoute path={Paths.SEARCH}>
            <StyledPageContainer maxWidth={false} style={{ background: "white", top: 0 }}>
              <SearchMobile />
            </StyledPageContainer>
          </SentryRoute>
          <SentryRoute path={Paths.WATCHLIST}>
            <StyledPageContainer maxWidth={false}>
              <Watchlist />
            </StyledPageContainer>
          </SentryRoute>
          <SentryRoute path={Paths.SETTINGS}>
            <StyledPageContainer maxWidth={false}>
              <Settings />
            </StyledPageContainer>
          </SentryRoute>
          <SentryRoute path={Paths.HOME}>
            <StyledPageContainer maxWidth={false}>
              <Home />
            </StyledPageContainer>
          </SentryRoute>
          <SentryRoute path={Paths.SIGN_UP}>
            <Redirect to={Paths.HOME} />
          </SentryRoute>
          <SentryRoute path={Paths.LANDING}>
            <Redirect to={Paths.HOME} />
          </SentryRoute>
        </Switch>
      </StyledMain>
      <Footer />
      <InsightDrawer />
      <InsightPopper />
      {
        matchesDownSm ? (
          <BottomNavigation />    
        ) : null
      }
    </Box>
  );
}

export default Sentry.withProfiler(App);
