import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import { useTheme } from '@mui/material/styles';

import { 
  Grid,
  Box,
  BoxPrimary,
} from "src/components/mui";
import { useRatingsBySymbolAndDate } from 'src/hooks';
import { ModelStringKeyConditionInput } from 'src/types';

function AnalystActions({
  symbol,
  datetime
} : {
  symbol: string | undefined,
  datetime: Partial<ModelStringKeyConditionInput>
}) {
  const theme = useTheme();

  const {
    data,
    isLoading,
    isFetched
  } = useRatingsBySymbolAndDate(symbol, datetime);

  if(isLoading || !isFetched) {
    return (
      <BoxPrimary>
        <Skeleton variant="rectangular" />
        <Skeleton variant="rectangular" />
        <Skeleton variant="rectangular" />
        <Skeleton variant="rectangular" />
        <Skeleton variant="rectangular" />
      </BoxPrimary>
    )
  }

  return (
    <Grid container spacing={2}>
      {
        data?.items.map((action) => {
          const { id, actionCompany, actionPt, datetime, ratingCurrent, ratingPrior } = action;

          const year = datetime.slice(0, 4);
          const month = datetime.slice(5, 7);
          const day = datetime.slice(8, 10);

          return(
            <Grid key={id} item xs={12} sm={6} display="flex">
              <Box 
                display="flex" 
                flexDirection="column" 
                justifyContent="center" 
                alignItems="center"
                p={1} 
                sx={{ 
                  backgroundColor: "#F5F8FF", 
                  borderRight: `2px solid ${theme.app.border.color.secondary}`, 
                  borderBottomLeftRadius: theme.app.borderRadius.cardPrimary,
                  borderTopLeftRadius: theme.app.borderRadius.cardPrimary,
                }}
              >
                <Typography variant="h6" color="textSecondary">{day}</Typography>
                <Typography variant="h6" color="textSecondary">{month}</Typography>
                <Typography variant="body2" color="textSecondary">{year}</Typography>
              </Box>
              <Box 
                display="flex" 
                flexDirection="column" 
                flexGrow={1} 
                p={1} 
                sx={{ 
                  backgroundColor: "#F5F8FF",
                  borderBottomRightRadius: theme.app.borderRadius.cardPrimary,
                  borderTopRightRadius: theme.app.borderRadius.cardPrimary,
              }}>
                <Typography variant="h6" color="textSecondary">{actionCompany}</Typography>
                <Typography variant="body1" color={theme.palette.primary.main} sx={{ textTransform: 'capitalize' }}>{actionPt}</Typography>
                <Typography variant="body1" color="textSecondary">
                  {
                    ratingPrior 
                      ? `Rating: ${ratingPrior} to ${ratingCurrent}`
                      : `Rating: ${ratingCurrent}`
                  }
                </Typography>
              </Box>
            </Grid>
          )
        })
      }
    </Grid>
  );
}

export default AnalystActions;